.App {
  text-align: center;
  background: #F5F5F5;
}

.mainFooter {
  width: 100%;
  background: white;
  border-top: 1px solid #EBECF0;
  bottom:0;
  left: 0;
}

a {
  text-decoration: underline;
}

h3 {
  font-family: 'Switzer' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 130% !important;
}

h4 {
  font-family: 'Switzer';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.2px;
}

h5 {
  font-family: 'Switzer';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.3px;
}

h6 {
  font-family: 'Switzer' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 120% !important;
  letter-spacing: 0.3px !important;
  color: #000000 !important;
}

.subHeader {
  font-family: 'Switzer';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #7A869A;
}

.bold18 {
  font-family: 'Switzer';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #1A1A1A;
}

.regular16 {
  font-family: 'Switzer';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.2px;
}

.medium14 {
  font-family: 'Switzer';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: #7A869A;
}

.medium18 {
  font-family: 'Switzer';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #1A1A1A;
}

.regular16 {
  font-family: 'Switzer';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #7A869A;
}

.regular14 {
  font-family: 'Switzer';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  /*text-decoration-line: underline;*/
  color: #7A869A;
}

.regular18 {
  font-family: 'Switzer';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #1A1A1A;
}

.regular12 {
  font-family: 'Switzer';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  color: #7A869A;
}

.MuiStepLabel-iconContainer .svgContainer {
  display:inline-block;
  position:relative; 
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  max-height: 24px;
}

.MuiStepLabel-labelContainer .Mui-disabled .stepBottomText span {
  font-family: 'Switzer';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #7A869A;
}

.MuiStepLabel-labelContainer .Mui-disabled .stepTopText span {
  font-family: 'Switzer';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #A5ADBA;
}

.MuiStepLabel-root .Mui-active + span .stepTopText {
  display: block !important;
}

.MuiStepLabel-root .Mui-active + span .stepBottomText {
  display: block !important;
}

.MuiStepLabel-labelContainer .stepTopText svg {
  position: relative;
  top: 5px;
  left: 5px;
  display:none;
}

.MuiStepLabel-labelContainer .Mui-completed .stepTopText svg {
  visibility: visible !important;
}

.mainStepperContainer {
  width:100%;
  padding: 8px;
  padding-top: 16px;
  border: 1px solid #EBECF0;
  border-radius: 8px;
  gap: 16px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
  margin-bottom: 24px;
}

.bankGrid .logoHolder {
  width: 40px;
  height: 40px;
  padding: 9px;
  background: #FFFFFF;
  border: 1.42857px solid #EBECF0;
  box-shadow: 0px 1.42857px 4.28571px rgba(0, 0, 0, 0.12);
  border-radius: 142.857px
}

.bankGrid .logoHolder img {
  width: 100%;
  height: 100%;
}

.bankGrid .bankName {
  display:inline-block;
  padding-left: 8px;
  margin: auto;
  color: #00497B;
  flex-grow: 1;
}

.bankGrid .arrowRight {
  margin:auto;
  display:inline-block;
  width: 24px;
}

.bankDisabled .arrowRight {
  display:none;
}

.bankGrid .disabledText {
  display: none;
  margin:auto;
  text-align: right;
  font-family: 'Switzer';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: right;
  letter-spacing: 0.2px;
  color: #1A1A1A;
  flex-grow: 1;
}

.bankDisabled .disabledText {
  display:inline-block;
}

.bankDisabled .bankName {
  color: #1A1A1A;
  opacity: 0.5;
}

.bankDisabled .logoHolder {
  opacity: 0.5;
}

.bankDisabled .logoHolder img {
  opacity: 0.5;
}

@keyframes fadeInLogo { 
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.MuiGrid-container > .MuiDivider-vertical.MuiDivider-flexItem {
  margin-right: -1px;
}